<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="450">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">{{ isCreate ? '创建' : '编辑' }}试题库</span>
                </v-card-title>
                <div class="form-content mx-12">
                    <form class="">
                        <v-text-field
                            label="名称"
                            v-model="name"
                            required
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"
                        ></v-text-field>

                        <v-text-field
                            label="说明"
                            v-model="desc"
                            required
                            @input="$v.desc.$touch()"
                            @blur="$v.desc.$touch()"
                        ></v-text-field>
                    </form>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    name: "EditQuestionLibDlg",

    mixins: [validationMixin],
    validations: {
        name: { required, maxLength: maxLength(200) },
        desc: { required, maxLength: maxLength(200) },
    },

    data: function () {
        return {
            dialog: false,
            isCreate: true,
            
            lib_id: "",
            name: "",
            desc: "",
        }
    },
    computed: {
        nameErrors() {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.maxLength &&
                errors.push("不能超过" + this.$v.name.maxLength + "个字符")
            !this.$v.name.required && errors.push("必须填写主题名称")
            return errors
        },
        descErrors() {
            const errors = []
            if (!this.$v.desc.$dirty) return errors
            !this.$v.name.maxLength &&
                errors.push("不能超过" + this.$v.desc.maxLength + "个字符")
            !this.$v.desc.required && errors.push("必须填写副标题")
            return errors
        },
    },
    methods: {
        openDlg(item = null) {
            if (item) {
                this.lib_id = item.lib_id
                this.name = item.name
                this.desc = item.desc

                this.isCreate = false

            } else {
                this.clear()
                this.isCreate = true
            }
            this.dialog = true
        },
        clear() {
            this.$v.$reset()
            
            this.lib_id = ""
            this.name = ""
            this.desc = ""
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$emit("submit", {
                    isCreate: this.isCreate,
                    lib_id: this.lib_id,
                    name: this.name,
                    desc: this.desc,
                })
                this.dialog = false
            }
        },
    },
    components: {},
};
</script>

<template>
    <v-container fluid class="px-8">
        <!-- 增加试题库按钮 -->
        <div class="add-btn-row">
            <div>
                <v-btn
                    tile
                    dark
                    color="cyan darken-1"
                    class="font-weight-bold text-body-1"
                    @click.stop="openEditQuestionLibDlg()"
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    创建试题库
                </v-btn>
            </div>
        </div>

        <!-- 列表 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer mt-5 table-bottom"
            :hide-default-footer="!datas || datas.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
            @click:row="clickRow"
        >
            <template v-slot:no-data>
                暂无试题库
            </template>
            <template v-slot:item.operate="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="purple"
                            @click.stop="openEditQuestionLibDlg(item)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑试题库</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="red darken-1"
                            @click.stop="openDelConfirmDlg(item.lib_id)"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除试题库</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <!-- 编辑试题库 对话框 -->
        <EditQuestionLibDlg ref="editQuestionLibDlg" @submit="submitQuestionLib" />
        <!-- 删除试题库 对话框 -->
        <ConfirmDlg
            ref="delQuestionLibConfirmDlg"
            title="删除试题库"
            text="确认 删除 当前试题库么？"
            keyword=" 删除 "
            @confirm="delQuestionLibConfirm"
        />

    </v-container>
</template>

<script>
import EditQuestionLibDlg from "@/views/components/dialog/EditQuestionLibDlg";
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';
import { getImgUrl } from "@/utils/util";
import { getQuestionLibs, delQuestionLib, editQuestionLib } from '@/api/admin_exam'

export default {
    name: "QuestionLib",
    data() {
        return {
            defaultImg4Topic: require('@/assets/images/admin/default-cover.jpeg'),
            headers: [
                { text: "试题库名称", value: "name", align: "start", sortable: false },
                { text: "试题库说明", value: "desc", align: "start", sortable: false },
                { text: "题目数量", value: "count", align: "center", sortable: false },
                // { text: "创建时间", value: "count", align: "center", sortable: false },
                { text: "操作", value: "operate", sortable: false, align: "center", width: 130 },
            ],
            datas: [],
        };
    },
    activated() {
        this.loadData();
    },
    methods: {
        getImgUrl,
        loadData() {
            getQuestionLibs().then((response) => {
                // console.log(response.data);
                this.datas = response.data;
                
            }).catch(function (err) {
                console.log(err);
            });
        },
        goto(id = "") {
            this.$router.push({
                path: "/exam-mgr/question-lib/question-list",
                query: { id: id },
            });
        },
        clickRow(item) {
            this.goto(item.lib_id);
        },

        // 打开编辑试题库对话框
        openEditQuestionLibDlg(questionLib = null) {
            this.$refs.editQuestionLibDlg.openDlg(questionLib);
        },
        // 编辑试题库info
        async submitQuestionLib(questionLib) {
            let msgType = questionLib.isCreate ? "创建" : "更新"
            let response = await editQuestionLib({
                id: questionLib.lib_id,
                name: questionLib.name,
                desc: questionLib.desc,
            })
            if (response.msg == "SUCCESS") {
                this.$store.dispatch("web/SetAlert", { type: "success", msg: msgType + "试题库成功"});

                // 更新列表数据
                let newData = response.data
                newData.count = 0

                if (questionLib.lib_id == '') {
                    this.datas.unshift(newData)
                } else {
                    for (let i in this.datas) {
                        if (this.datas[i].lib_id == questionLib.lib_id) {
                            this.datas[i] = newData
                            this.$set(this.datas, i, this.datas[i]);
                            break
                        }
                    }
                }
            } else {
                this.$store.dispatch("web/SetAlert", { type: "error", msg: msgType + "试题库失败" });
            }
        },

        // 打开删除试题库确认对话框
        openDelConfirmDlg(libId) {
            this.$refs.delQuestionLibConfirmDlg.openDlg(libId);
        },
        // 删除试题库确认
        async delQuestionLibConfirm(libId) {
            let response = await delQuestionLib(libId)
            if (response.msg == "SUCCESS") {
                for (let i = 0; i < this.datas.length; i++) {
                    if (this.datas[i].lib_id == libId) {
                        this.datas.splice(i, 1)
                        break
                    }
                }
                this.$store.dispatch("web/SetAlert", { type: "success", msg: "删除试题库成功"});

            } else {
                this.$store.dispatch("web/SetAlert", { type: "error", msg: "删除试题库失败" });
            }
        },
    },
    components: {
        EditQuestionLibDlg,
        ConfirmDlg,
    },
};
</script>

<style lang="scss" scoped>
.alert-area {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
}
.add-topic {
    cursor: pointer;
}
.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.img-wrapper {
    height: 220px;
    width: 200px;
    overflow: hidden;
    .img-scale {
        &:hover {
            transform: scale(1.2); // 放大1.2倍
            transition: all .5s; // 设置加载时间
        }
    }
}
.text-wrapper {
    width: 200px;
    padding: 16px 0;
}
.btn-del-topic {
    position: absolute;
    top: 15px;
    right: 10px;
    &:hover {
        background-color: #eee !important;
    }
}
.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>